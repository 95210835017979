*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide-border:before,.hide-border:after{
  display: none;
}
.dd-item::marker{
  display: none !important;
  font-size: 0px;
} 
.btn-outline-secondary:hover{
  color: #ffffff !important;
}

.rsm-svg{
  height: 100%;
  width: 100%;
}

.modal{
z-index: 11112 !important;
}



.image-gallery-play-button{
  display: none;
}

.image-gallery{
  width: 50%;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 70px !important;
  width: 60px !important;
}

.card .header .header-dropdown li .dropdown-menu, .header-dropdown .dropdown-menu{
  background: #17191c; 
    border-radius: .55rem;
    overflow: hidden;
    transform: none !important;
    left: auto !important;
    right: 10px !important;
    padding: 10px;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.5);
    border: 0;
    top:10px !important;
    color: #999 !important;
}
.card .header .header-dropdown li a{
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

.card .header .header-dropdown li a:hover{
  color: #fff !important;
}
.accordion .card .card-header{
  background-color: transparent !important;
}
.accordion .card .card-header .btn-link{
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container{
  display: none !important;
}

.hide-toggle:after{
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: "" !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control{
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.hide-toggle .btn-outline-secondary{
  height: calc(1.5em + 0.75rem + 5px) !important;
}
.jvectormap-container{
  height: 300px;
}
.jvectormap-zoomin{
  position: absolute;
    top: 0px;
    background: #000;
    color: #fff;
    padding: 2px 7px;
}
.jvectormap-zoomout{
  position: absolute;
    top: 27px;
    background: #000;
    color: #fff;
    padding: 2px 7px;
}
.list-group-item + .list-group-item{
  border-top-width:1px !important;
}
.hclass {
  left: -40px !important;
}
.container-summary{
  margin-top: 10px;
}

.container-card{
  display: flex;
  flex-direction: row;
}
.card-item {
  width: 200px;
  margin: 10px;

}
.fullWidthAndHeight{
  width: 100%;  
  display: flex;
  flex-direction: row;
}

.centerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.contercontainer Loader{
  width: 100px;  
  height: 100px;
}

.device-container{
  display: flex;
  flex-direction: column;
}

.item-device{
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
  padding: 10px;
}
.grid-item {
  
}

.device-img-size{
  width: 100px;
  height: 100px;
}

.indicator-img-size{
  width: 50px;
  height: 50px;
}

.image-item{
  display: flex;
  flex-direction: column;
 
}

.grid-item-sub {
  background-color: red;
  margin: 5px;
}

.body-grid{
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: space-between;
  row-gap: 5px;
  column-gap: 5px;
}

.body-grid-item-image {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 10px;

 }
 .body-grid-item-text {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-left: 10px;
 }
 .indicator-img{
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 5;
  grid-column-end: 6;
  align-self: top;
  margin-right: 15px;
  margin-top: 15px;

 }

 .body-grid-bottom-item{
  width: 100%;
  grid-row-start: 5;
  grid-column-start: 1;
  grid-column-end: 7;
  display: flex;
  flex: 0 0 100%;

 }
 .padding-value{
   padding: 5px;
   text-align: center;
 }

 .card-design {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(238, 231, 231);

}

.datePickerDesign{
  border-radius: 1px;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.rigthCenterContainer{
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 5px;
}

.padding20{
 padding: 20px;
}
.padding10{
  padding: 10px;
 }
.margin10{
  margin: 20px;
}

.primaryButton{
  border-radius: 3px;
  background-color: rgb(121, 124, 124);
  border: 1px solid rgb(2, 17, 17);
  padding-left:  20px;
  padding-right:  20px;
  padding-top:5px;
  padding-bottom:5px;
  color: white;
  margin: 10px;
 }
 .fullWidth{
   width: 100%;
 }

 .font-big{
   font-size: 1.3rem;
 }

 .font-medium{
  font-size: 1.0rem;
}


 .device-details-container{
   display: grid;
   grid-template-rows: 1fr 1fr;
   grid-template-columns: 1fr 1fr;
   row-gap: 10px;
   column-gap: 10px;
 }

 .device-details-item{

  /* background-color: #345679; */

 }

 .device-details-item-2{
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start:1;
  grid-column-end: 3;

 }

 .background-login{
   background-image: url(../src/assets/images/bg/login-background.png);
   background-size: cover;
 }
 .background-gray{
   background-color: gray;
 }

 .details-item-1{
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: 1fr;
   place-items: left;
   
 }



 .display-two-column{
   display: flex;
   justify-content: space-between;
 }

 .map-container{
   height: 100vh;
   width: 100%;
 }

 .display-none{
   display: none;
 }
 
 .dashboard-container{
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
 }
 .device-main-container{
   display: grid;
   grid-template-columns: 1fr;
 }

 .device-main-container-avtive-2{
  display: grid;
  grid-template-columns: 1fr minmax(300px, auto);

}

 .height100{
   height: 100px;
 }


.marquee {
  top: 50px;
  position: relative;
  box-sizing: border-box;
  animation: marquee 20s linear infinite;
  margin: 0 auto;
  text-align: center;
  color: #000;
}

@keyframes marquee {
from {
  transform: translateY(0);
}
to {
  transform: translateY(-150%);
}
}

.padding0{
  padding: 0px;
}

.margin0{
  margin: 0px;
}

.font-size-14px{
  font-size: 14px;
}


.marginright10{
  margin-right: 10px;
}


 .list-container{
   display: grid;
   gap: 10px;
   grid-template-rows: auto;
   grid-template-columns: repeat(3, 1fr);
 }



 .list-container-item{
   display: grid;
   grid-template-columns: auto 1fr 30px;
   gap: 2px;

 }
 .list-left-icon{
   display: flex;
   flex-direction: column;
   justify-items: center;
 }

 .card-design-white {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(255, 255, 255);
}

.indicator-image{
  margin-right: 5px;
  margin-top: 2px;
}
.list-left-icon-layout{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.maquee-items{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}
.marqueeitem{
  padding: 2px; 
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.error-device-dashboard{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 10px;
  flex: 1fr;
}

.disconnected-header{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(255, 0, 0);
  font-size: 1.1rem;
  color: white;
  padding: 10px;
  text-align: center;
}

/* .center-text{
  text-align: center;
} */

.area-design-dotted{
  border-style: dashed;
  border-color: red;
  border-width: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.one-item-per-line{
  display: grid;
  gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
 }

 .card-design-error {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  transition: 0.3s;
  background-color: rgb(255, 0, 0);
  display: grid;
  place-items: center;
  color: white;

}

.font-size-medium{
  font-size: 1.2rem;
}

.popupClass{
  width: auto;
}