.dndflow {
    flex-direction: column;
    display: flex;
    height: 100%;
  }
  
  .dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
  }
  
  .dndflow aside > * {
    margin-bottom: 10px;
    cursor: grab;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
  }

  .dndflow .dndnode {
    align-items: center;
    border: 1px solid #1a192b;
    border-radius: 2px;
    cursor: grab;
    display: flex;
    height: 20px;
    justify-content: center;
    padding: 4px;
}
.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.label{
  border-radius: 25px;
  width: 50px;
  height: 50px;
}
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 180px;
    }
  }